<template>
  <div class="main-page card-detection">
    <custom-header></custom-header>
    <div class="desc">
      <h4>卡证OCR识别</h4>
      <p>支持身份证、银行卡等证件的全字段识别</p>
    </div>
    <van-tabs
      v-model="activeTab"
      @change="changeTab"
      class="custom-headers-com"
      swipeable
    >
      <van-tab name="1">
        <template #title>
          <div class="tab-headers">身份证识别</div>
        </template>
        <div class="image-box" :class="[customResultData.success ? 'show-result' : '', !customResultData.resultType ? 'column' : '']">
          <div class="img-angle">
            <div class="img-cover">
              <div class="font-tip" v-if="customResultData.resultType">{{customResultData.resultType == 1 ? '人像面' : '国徽面'}}</div>
              <van-image fit="contain" :src="data[activeTab].url" lazy-load class="main-img">
                <template v-slot:loading>
                  <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
                </template>
              </van-image>
              <div class="qr-scanner" v-if="data[activeTab].loading">
                <div class="box">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            
            <ul class="result-info" v-if="customResultData.resultType == 1">
              <li>
                <label>姓名</label>
                <span>{{customResultData.name || '- -'}}</span>
              </li>
              <li>
                <label>性别</label>
                <span>{{customResultData.gender || '- -'}}</span>
              </li>
              <li>
                <label>民族</label>
                <span>{{customResultData.nation || '- -'}}</span>
              </li>
              <li>
                <label>出生日期</label>
                <span>{{customResultData.birthday || '- -'}}</span>
              </li>
              <li>
                <label>住址</label>
                <span>{{customResultData.address || '- -'}}</span>
              </li>
              <li>
                <label>身份证号</label>
                <span>{{customResultData.idCardNum || '- -'}}</span>
              </li>
            </ul>

            <ul class="result-info" v-if="customResultData.resultType == 2">
              <li>
                <label>签发机关</label>
                <span>{{customResultData.signOrganization || '- -'}}</span>
              </li>
              <li>
                <label>有效期限</label>
                <span>{{customResultData.validDate || '- -'}}</span>
              </li>
            </ul>

            <div class="font-tip" v-if="customResultData.success === false || (customResultData.success && !customResultData.resultType)">请重新上传清晰完整的身份证正/反面图片</div>
          </div>
        </div>
        <div class="desc-info">
          <ul>
            <li>
              <p>有无ps</p>
              <p>{{customResultData.resultType ? (customResultData.isPs || '- -') : '- -'}}</p>
            </li>
            <li>
              <p>清晰度</p>
              <p>{{customResultData.resultType ? (Number(customResultData.definition || '').toFixed(2) || '- -') : '- -'}}</p>
            </li>
            <li>
              <p>分辨率</p>
              <p>{{customResultData.resultType ? (customResultData.resolutions.length ? `${customResultData.resolutions[0]} * ${customResultData.resolutions[1]}` :  '- -') : '- -'}}</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>倾斜角度</p>
              <p>{{customResultData.resultType ? (Number(customResultData.angle || '').toFixed(2) || '- -') : '- -'}}</p>
            </li>
            <li>
              <p>是否完整</p>
              <p>{{customResultData.resultType ? (customResultData.isFully || '- -') : '- -'}}</p>
            </li>
            <li>
              <p>是否模糊</p>
              <p>{{customResultData.resultType ? (customResultData.isVague || '- -') : '- -'}}</p>
            </li>
          </ul>
        </div>
      </van-tab>

      <van-tab name="2">
        <template #title>
          <div class="tab-headers">银行卡识别</div>
        </template>
        <div class="image-box" :class="[customResultData.success ? 'show-result' : '', !customResultData.success ? 'column' : '']">
          <div class="img-angle">
            <div class="img-cover">
              <img :src="data[activeTab].url" alt="" class="main-img">
              <div class="qr-scanner" v-if="data[activeTab].loading">
                <div class="box">
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <ul class="result-info" v-if="customResultData.success">
              <li>
                <label>银行名称</label>
                <span>{{customResultData.bankName || '- -'}}</span>
              </li>
              <li>
                <label>卡号</label>
                <span>{{customResultData.bankcardNo || '- -'}}</span>
              </li>
              <li>
                <label>卡类型</label>
                <span>{{customResultData.bankcardType || '- -'}}</span>
              </li>
              <li>
                <label>有效期</label>
                <span>{{customResultData.bankcardValid || '- -'}}</span>
              </li>
            </ul>
            <div class="font-tip" v-if="customResultData.success === false">请重新上传清晰完整的银行卡图片</div>
          </div>
        </div>
        <div class="desc-info">
        </div>
      </van-tab>
    </van-tabs>

    <van-button type="info" class="choose-btn" :disabled="data[activeTab].loading" @click="chooseExample">选择图片</van-button>
    <custom-footer></custom-footer>

    <choose-example v-if="chooseShow" :dataList="customExampleList" @goBack="hideExample" @onDetect="onDetect" :type="activeTab == 1 ? 'idCard' : 'bankCard'"></choose-example>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ChooseExample from "@/components/ChooseExample.vue";

import { DATA } from '@/remote';
import { file } from '@babel/types';

const TAB_LIST = [
  {key: '1', demoKey: 3, exampleKey: 'idCard', method: 'idCardCheck'},
  {key: '2', demoKey: 10, exampleKey: 'bankCard', method: 'bankCardCheck'}
]

const TAB_OBJ = TAB_LIST.reduce((obj, item) => {
  obj[item.key] = item;
  return obj;
}, {});

const DATA_OBJ = TAB_LIST.reduce((obj, item) => {
  obj[item.key] = {
    url: '',
    loading: false,
    resultData: {},
    exampleList: [],
  };
  return obj;
}, {});

export default {
  name: "card-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
  },
  data(){
    return {
      tabObj: TAB_OBJ,
      data: DATA_OBJ,
      chooseShow: false,
      activeTab: TAB_LIST[0].key,
    };
  },
  computed: {
    customExampleList() {
      return this.data[this.activeTab].exampleList || [];
    },
    customResultData() {
      return this.data[this.activeTab].resultData;
    }
  },
  mounted() {
    this.getDemo(TAB_LIST[0].key, TAB_LIST[0].demoKey);
  },
  methods: {
    getDemo(activeTab, fileType) {
      DATA.getDemo({fileType}).then(res => {
        let {code, data = []} = res || {};
        if (code == 200) {
          this.data = {
            ...this.data,
            [activeTab]: {
              ...this.data[activeTab],
              url: data[0],
              exampleList: data 
            }
          }
          if (!this.data[activeTab].resultData || !this.data[activeTab].resultData.success) {
            this.detection(activeTab, data[0]);
          }
        }
      })
    },

    // 切换tab
    changeTab(value) {
      this.activeTab = value;
      if (!this.customExampleList || !this.customExampleList.length) {
        this.getDemo(value, this.tabObj[value].demoKey);
      }
    },

    // 检测
    detection(activeTab, fileUrl) {
      this.data = {
        ...this.data,
        [activeTab]: {
          ...this.data[activeTab],
          loading: true,
          resultData: {},
        }
      }
      let method = this.tabObj[activeTab].method;
      DATA[method]({fileUrl}).then(res => {
        let {code} = res || {};
        let data = res.data || {};
        this.data = {
          ...this.data,
          [activeTab]: {
            ...this.data[activeTab],
            loading: false,
            resultData: {
              ...this.data[activeTab].resultData,
              ...data,
              success: code == 200,
              resultType: (data.classificationResults || '').includes('人像面') ? 1 : ((data.classificationResults || '').includes('国徽面') ? 2 : 0),
            }
          }
        }
      })
    },

    // 选择示例
    chooseExample() {
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    onDetect(fileUrl) {
      this.hideExample();
      this.data = {
        ...this.data,
        [this.activeTab]: {
          ...this.data[this.activeTab],
          url: fileUrl,
        }
      }
      this.detection(this.activeTab, fileUrl);
    },
  }
};
</script>

<style lang="less">
  @import '../../assets/less/cardDetection';
</style>
